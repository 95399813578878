<template>
   <div>
      <v-snackbar 
         v-if="showDialog && !dialogIsError" 
         v-model="showDialog" 
         color="success" 
         :absolute="true"
         :top="true"
         :right="true"
         :timeout="1000">
         <div clas="d-flex tile">
            <v-icon class="tile align-self-center">{{icons.mdiCheckCircle}}</v-icon><span class="tile align-self-center"> Update Successful</span>
         </div>
      </v-snackbar>
      <v-snackbar 
         v-else-if="showDialog && dialogIsError" 
         v-model="showDialog" 
         color="error" 
         :absolute="true"
         :top="true"
         :right="true"
         :timeout="2000">
         <div clas="d-flex tile">
            <v-icon class="tile align-self-center">{{icons.mdiAlertCircle}}</v-icon><span class="tile align-self-center"> Update Failed. {{dialogMessage}}</span>
         </div>
      </v-snackbar>
      <v-dialog v-model="dialog" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Loading data...
          <v-progress-linear indeterminate color="white" class="mb-0" />
        </v-card-text>
      </v-card>
    </v-dialog>
   </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { mdiCheckCircle, mdiAlertCircle } from '@mdi/js'

export default {
   name: "Dialog",
   setup() {
      return {
         icons: {
            mdiCheckCircle,
            mdiAlertCircle
         }
      }
   },
   data() {
      return {
         dialog: false,
      }
   },
   computed: {
      ...mapState({
         isFetching: state => state.request.isFetching,
         isSuccess: state => state.request.isSuccess,
         isFail: state => state.request.isFail,
         dialogMessage: state => state.dialog.message,
         dialogIsError: state => state.dialog.isError,
         returnLink: state => state.dialog.returnLink,
      }),
      showDialog: {
         get() {
            return this.$store.state.dialog.showDialog;
         },
         set(value) {
            this.setShowDialog(value);
         }
      },
   },
   methods: {
      ...mapActions(["setShowDialog"], "dialog"),
   },
    watch: {
      isFetching (val) {
         this.dialog = (val === true)
      },
    },
};
</script>
