<template>
  <div>
    <router-view></router-view>
    <Dialog />
  </div>
</template>

<script>
import Dialog from "@/components/Dialog"
export default {
  name: 'Container',
  components: {
    Dialog
  },
}
</script>
